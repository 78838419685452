.socials-container {
  position: fixed;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.socials {
  display: flex;
  flex-direction: column;

  /* gap: 1vh; */
}

.social-icon-linkedIn {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #425055;
  background-color: #9daab1;
  font-size: 3vh;
  transition: transform 0.3s ease-in-out;
  transform: translateX(-76%);
  width: 17.9vh; /* Set a fixed width */
  height: 5vh;
  border-radius: 0 10px 10px 0;
}

.social-icon-github {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #9daab1;
  background-color: #425055;
  font-size: 3vh;
  transition: transform 0.3s ease-in-out;
  transform: translateX(-74%);
  width: 16.5vh; /* Set a fixed width */
  height: 5vh; /* Set a fixed height */
  border-radius: 0 10px 10px 0;

}

.social-icon-email {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #425055;
  background-color: #9daab1;
  font-size: 3vh;
  transition: transform 0.3s ease-in-out;
  transform: translateX(-71%);

  width: 14.68vh; /* Set a fixed width */
  height: 5vh; /* Set a fixed height */
  border-radius: 0 10px 10px 0;
}
.social-icon-resume {
  display: flex;
  justify-content: center;
  align-items: center;

  color: #9daab1;
  background-color: #425055;
  font-size: 3vh;
  transition: transform 0.3s ease-in-out;
  transform: translateX(-78%);

  width: 19.2vh; /* Set a fixed width */
  height: 5vh; /* Set a fixed height */
  border-radius: 0 10px 10px 0;

}

.text-icon {
  display: inline-block;
  margin-right: 1vh;
  padding-left: 2vh;
}

.text-icon:hover {
  transform: translateX(0%);
}

.social-icon-linkedIn:hover {
  transform: translateX(0%);
}

.social-icon-github:hover {
  transform: translateX(0%);
}

.social-icon-email:hover {
  transform: translateX(0%);
}

.social-icon-resume:hover {
  transform: translateX(0%);
}

.icon-fill-light {
  background-color: #9daab1;
  padding-right: 1vh;
}

.linkedIn-icon {
  padding-left: 0.4vh;
  padding-right: 0.7vh;
}

.github-icon {
  padding-left: 0.4vh;
  padding-right: 0.1vh;
}

.email-icon {
  padding-left: 0.4vh;
  padding-right: 0.6vh;
}

.resume-icon {
  padding-left: 1.9vh;
  padding-right: 0.1vh;
}

@media (max-width: 768px) {
  .socials-container {
    display: none;
  }
}
