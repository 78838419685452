.code-button {
  background-color: #9bbbd4;
}

.view-button {
  background-color: #576669;
}

.code-text {
  color: white;
}

.btn-disabled {
  pointer-events: none;
  opacity: 0.15;
  cursor: not-allowed;
}

@media (max-width: 768px) {
  .btn-responsive {
    padding: 12px 16px; /* Increase button padding */
    font-size: 16px; /* Increase font size */
    width: 48%; /* Almost half the container width */
    margin: 0 1%;
  }
}
