.collab-button {
  background-color: #576669;
  color: white;
}

.light-icon {
  color: #576669;
}

.dark-icon {
  color: #425055;
}
