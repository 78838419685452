body,
html {
  height: 100%;
  margin: 0;
}

#backgroundWrapper {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;

  background-image: url("https://imagedelivery.net/Umg8ReSrPBwyC9Uh-9aekA/c4ae05f6-354b-4e5c-1421-d67c2a58e500/highresBackground");
  background-size: cover;
  background-repeat: no-repeat;
}

@media (max-width: 767px) {
  #backgroundWrapper {
    background-size: 3258px 1672px;
    background-position: left bottom;
  }
}

body {
  font-family: "Kanit", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
